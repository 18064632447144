(function () {
  'use strict';

  angular
    .module('neo.home.entitats.entitat.printCards')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('home.entitats.entitat.printCards', {
        url: '/print-cards',
        templateUrl: 'home/entitats/entitat/print-cards/print-cards.tpl.html',
        controller: 'PrintCardsCtrl',
        controllerAs: 'vm',
        resolve: {
          townHall: function ($stateParams, TownHallAssociated) {
            return TownHallAssociated.query({id: $stateParams.entitatId}).$promise;
          },
          athletesCards: function ($stateParams, EntityLicenses) {
            return EntityLicenses.query({id: $stateParams.entitatId}).$promise;
          },
          categories: function (Categories) {
            return Categories.query().$promise;
          },
          sports: function (Sports) {
            return Sports.query().$promise;
          },
          licencesTypes: function (LicenseTypes) {
            return LicenseTypes.query().$promise;
          },
          currentSeason: function (CurrentSeason) {
            return CurrentSeason.get().$promise;
          }
        }
      });
  }
}());
